.highlight-code-line {
  background-color: #ffcfcf !important;
}

.monaco-editor .blur-line::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #ffffff;
  filter: opacity(70%);
}

.monaco-editor .blur-line {
  position: relative;
  z-index: 1;
}
