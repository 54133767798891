.rdrStaticRange {
  font-family: var(--font-family);
}

.rdrDateDisplayItem input {
  font-family: var(--font-family);
}

.rdrMonthAndYearPickers select {
  font-family: var(--font-family);
}
