.handle-outer {
  background: transparent;
}

.no-icon > svg {
  display: none;
}

/* HORIZONTAL */

.handle-horizontal-divider::before {
  height: 50%;
  background: var(--grey-300);
  width: 1px;
  content: '';
}

.handle-horizontal-divider::after {
  height: 50%;
  background: var(--grey-300);
  width: 1px;
  content: '';
}

.handle-horizontal-active::before {
  content: '';
  display: block;
  height: 50%;
  width: 1px;
  background: var(--primary-main);
  margin-bottom: 8px;
  z-index: 2;
}

.handle-horizontal-active::after {
  content: '';
  display: block;
  height: 50%;
  width: 1px;
  background: var(--primary-main);
  margin-top: 8px;
  z-index: 2;
}

.handle-horizontal:hover::before {
  content: '';
  display: block;
  height: 50%;
  width: 1px;
  background: var(--primary-main);
  margin-bottom: 8px;
  z-index: 2;
}

.handle-horizontal:hover::after {
  content: '';
  display: block;
  height: 50%;
  width: 1px;
  background: var(--primary-main);
  margin-top: 8px;
  z-index: 2;
}

.handle-horizontal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  position: relative;
}

.handle-horizontal:hover > svg {
  display: block;
}

.handle-horizontal-active > svg {
  display: block;
}

/* VERTICAL */

.handle-vertical-divider::before {
  height: 1px;
  background: var(--grey-300);
  width: 50%;
  content: '';
}

.handle-vertical-divider::after {
  height: 1px;
  background: var(--grey-300);
  width: 50%;
  content: '';
}

.handle-vertical:hover::before {
  content: '';
  display: block;
  height: 1px;
  width: 50%;
  background: var(--primary-main);
  margin-right: 8px;
  z-index: 2;
}

.handle-vertical:hover::after {
  content: '';
  display: block;
  height: 1px;
  width: 50%;
  background: var(--primary-main);
  margin-left: 8px;
  z-index: 2;
}

.handle-vertical-active::before {
  content: '';
  display: block;
  height: 1px;
  width: 50%;
  background: var(--primary-main);
  margin-right: 8px;
  z-index: 2;
}

.handle-vertical-active::after {
  content: '';
  display: block;
  height: 1px;
  width: 50%;
  background: var(--primary-main);
  margin-left: 8px;
  z-index: 2;
}

.handle-vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  position: relative;
}

.handle-vertical:hover > svg {
  display: block;
}

.handle-vertical-active > svg {
  display: block;
}
