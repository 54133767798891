.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable ::-moz-scrollbar {
  display: none;
}

.scrollable {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
